import myresume from '../assets/pdf/williamchung.pdf'
import profile from '../assets/png/profile.jpg'


export const headerData = {
    name: 'William Chung',
    title: "Full Stack Software Developer",
    desciption:"As a Marine Corps veteran and passionate full-stack software developer, I am driven by my love for innovative problem-solving and leveraging technology. Inspired by a dedicated NCO during my military service, I embarked on a self-directed journey of learning, fueling my deep passion for software development. With a rich cultural background, strong work ethic, and commitment to collaboration, I thrive in team environments, embracing continuous learning and pushing the boundaries of achievement. My goal is to craft cutting-edge solutions and contribute to the success of my team and the broader software development community.",
    image: profile,
    resumePdf: myresume
}
