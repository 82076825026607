export const experienceData = [
    {
        id: 1,
        company: 'Immersed',
        jobtitle: 'Full Stack Developer',
        startYear: '2022',
        endYear: '2023'
    },
    {
        id: 2,
        company: 'S-Services',
        jobtitle: 'Operations and Maintenance Engineer',
        startYear: '2020',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'United States Marine Corps',
        jobtitle: 'Satellite Transmission Operator',
        startYear: '2015',
        endYear: '2019'
    },
]